import { fetchData, fetchDataOutside } from "../helpers/apiConnect";
import { defaultHeader, formHeader } from "../helpers/globalConstants";
const MODULE_DETAILS = {
	restaurants: { url: "restaurants", name: "title" },
	restaurant_food_category: { url: "restaurant_food_category", name: "title" },
	restaurant_food_items: { url: "restaurant_food_items", name: "title" },
	carts: { url: "carts", name: "cart" },
	updateOrder: { url: "carts/update_order", name: "updateOrder" },
	createStripePaymentIntent: {
		url: "carts/iPay/stripe-payment-intent/create",
		name: "createStripePaymentIntent",
	},
	payments: { url: "payments", name: "payments" },

	days: { url: "days", name: "days" },
};
class DataService {
	data = [];

	constructor(moduleString) {
		this.moduleString = moduleString;
		this.module = MODULE_DETAILS[this.moduleString];
		this.moduleUrl = this.module.url;
		this.auth = false;
	}

	validateInputs(inputData) {
		// Check if blankVariable is blank
		if (!inputData["first_name"]) {
			return false;
		}
		if (!inputData["last_name"]) {
			return false;
		}
		// Check if emailVariable is a valid email format
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(inputData["email"])) {
			return false;
		}

		// Check if phoneVariable is a valid phone number (Canadian format)
		const phoneRegex =
			/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/;
		if (!phoneRegex.test(inputData["phone"])) {
			return false;
		}

		// All validations passed
		return true;
	}

	getRestaurantProfile(keyName) {
		let profileString = this.getValue(keyName + "_restaurant_profile");
		let profileObj = profileString == null ? [] : JSON.parse(profileString);
		return profileObj;
	}

	setRestaurantProfile(keyName, profileObj) {
		let profileString = JSON.stringify(profileObj);
		sessionStorage.setItem(keyName + "_restaurant_profile", profileString);
	}
	setCartParams(keyName, cartObj) {
		console.log("stored", keyName);
		let cartString = JSON.stringify(cartObj);
		sessionStorage.setItem(keyName + "cart_params", cartString);
	}

	getCartParams(keyName) {
		let cartString = this.getValue(keyName + "cart_params");
		let cartObj = cartString == null ? false : JSON.parse(cartString);
		return cartObj;
	}

	setAddress(keyName, addrObj) {
		let addressString = JSON.stringify(addrObj);
		sessionStorage.setItem(keyName + "address", addressString);
	}
	setDistance(keyName, distance) {
		sessionStorage.setItem(keyName + "distance", distance);
	}
	// 7start
	setZone(keyName, zone) {
		sessionStorage.setItem(keyName + "zone", JSON.stringify(zone));
	}
	getZone(keyName) {
		return JSON.parse(sessionStorage.getItem(keyName + "zone"));
	}
	// 7end
	getDistance(keyName) {
		return sessionStorage.getItem(keyName + "distance");
	}
	getAddress(keyName) {
		let addressString = this.getValue(keyName + "address");
		let addrObj = addressString == null ? false : JSON.parse(addressString);
		return addrObj;
	}
	setCustomerID(keyName) {
		let customer_id =
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15);
		sessionStorage.setItem(keyName + "cust_id", customer_id);
	}
	getCustomerID(keyName) {
		return sessionStorage.getItem(keyName + "cust_id");
	}
	getCart(keyName) {
		let cartString = this.getValue(keyName + "_cart");
		let cart = cartString == null ? [] : JSON.parse(cartString);
		return cart;
	}

	getDeleveryMode(keyName) {
		let modeString = this.getValue(keyName + "delevery_mode");
		modeString = modeString == null ? 0 : modeString;
		return modeString;
	}

	getShopingTime(keyName) {
		let timeString = this.getValue(keyName + "shopping_time");
		timeString = timeString == null ? false : timeString;
		return timeString;
	}
	getShopingAddress(keyName) {
		let addressString = this.getValue(keyName + "address");
		let address = addressString == null ? {} : JSON.parse(addressString);
		return address;
	}
	setShoppingTime(keyName, timeString) {
		sessionStorage.setItem(keyName + "shopping_time", timeString);
	}
	setShoppingAddress(keyName, address) {
		let addressString = JSON.stringify(address);
		sessionStorage.setItem(keyName + "address", addressString);
	}

	setRoadDistance(keyName, roadDistance) {
		sessionStorage.setItem(keyName + "road_distance", roadDistance);
	}
	getRoadDistance(keyName) {
		let roadDistance = this.getValue(keyName + "road_distance");
		if (!roadDistance || roadDistance <= 0) {
			return 0;
		}
		return roadDistance;
	}
	isDeleveryModeEnabled(keyName) {
		let modeString = this.getValue(keyName + "delevery_mode");
		let status = modeString == null ? false : true;

		return status;
	}
	setDeleveryModeDisbaled(keyName) {
		sessionStorage.removeItem(keyName + "delevery_mode");
	}

	setCartDisbaled(keyName) {
		sessionStorage.removeItem(keyName + "_cart");
		sessionStorage.removeItem(keyName + "cart_params");
		this.setCustomerID(keyName);
	}
	getName(keyName) {
		let name = this.getValue(keyName + "profile_name");
		name = name == null ? "" : name;
		return name;
	}
	getPath() {
		let name = this.getValue("pathName");
		name = name == null ? "" : name;
		return name;
	}
	setPath(keyName) {
		sessionStorage.setItem("pathName", keyName);
	}
	setDeleveryMode(keyName, mode) {
		sessionStorage.setItem(keyName + "delevery_mode", mode);
		this.setCustomerID(keyName);
	}
	setCart(keyName, cartObj) {
		let cartString = JSON.stringify(cartObj);
		sessionStorage.setItem(keyName + "_cart", cartString);
	}
	setOffers(keyName, offers) {
		let offersString = JSON.stringify(offers);
		sessionStorage.setItem(keyName + "_offers", offersString);
	}
	getOffers(keyName) {
		let offersString = this.getValue(keyName + "_offers");
		let offers = offersString == null ? [] : JSON.parse(offersString);
		return offers;
	}
	setOfferRules(keyName, offers) {
		let offersString = JSON.stringify(offers);
		sessionStorage.setItem(keyName + "_offerRules", offersString);
	}
	getOfferRules(keyName) {
		let offersString = this.getValue(keyName + "_offerRules");
		let offers = offersString == null ? [] : JSON.parse(offersString);
		return offers;
	}
	/*
    1. Create an item with 
    */
	storeValue = (valueObj) => {
		sessionStorage.setItem(valueObj.name, valueObj.value);
	};
	/*
    1. Create an item with 
    */
	getValue = (name) => {
		return sessionStorage.getItem(name);
	};
	createData(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};

			options.url = url;

			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	createPayCollection(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/create_pay_collection";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	getProfile(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_user_profile";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getFoodProfile(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_food_profile";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getCartProfile(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_cart_profile";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getMonerisTicket(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_moneris_ticket";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getCoupens(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/find_available_coupens_at";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	createCart(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/create_cart";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	dumpCart(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/dump_cart";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	emailClientIssue(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/email_issue_on_client_end";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {}
	}
	preloadMoneris(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/preload_moneris";
			options.method = "post";
			options.header = header;
			options.auth = false;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {}
	}
	addMenuItem(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};

			options.url = url + "/add_daily_menu";

			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	createDataFromForm(inputParms, header = formHeader, url = this.moduleUrl) {
		return this.createData(inputParms, header, url);
	}

	updateDataFromForm(inputParms, id, header = formHeader, url = this.moduleUrl) {
		return this.updateData(inputParms, id, header, url);
	}
	updateData(inputParms, id, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/" + id;
			options.method = "put";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	getAbstractData(inputParams) {
		this.getData(inputParams).then((response) => {
			let abstractData = response.data.data.map((obj) => ({
				label: obj[this.module.name],
				value: obj.id,
			}));
			return abstractData;
		});
	}

	/*
    1. Create an item with 
    */

	uploadExcel(inputParms, header = formHeader) {
		try {
			let options = {};
			options.url = this.moduleUrl + "/upload_excel";
			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	/*
    2. Get items 
    */
	getCartStatus(key_name, order_id) {
		try {
			let cust_id = this.getCustomerID(key_name);
			let options = {};
			options.url = "/payments";
			options.method = "get";
			options.header = defaultHeader;
			options.auth = false;
			options.params = { cust_id: cust_id, order_id: order_id };
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	/*
    2. Get items 
    */
	getData(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url;
			options.method = "get";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	/*
    2. Get items 
    */
	findData(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url;
			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	/*
    2. Get items 
    */
	getIndividualData(
		id,
		inputParms,
		header = defaultHeader,
		url = this.moduleUrl
	) {
		try {
			let options = {};
			options.url = url + "/" + id;
			options.method = "get";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	/*
    2. Get items 
    */
	deleteData(inputParms, header = defaultHeader) {
		try {
			let options = {};
			options.url = this.moduleUrl + "/" + inputParms["id"];
			options.method = "delete";
			options.header = header;
			options.auth = this.auth;
			options.params = {};
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
}

export default DataService;
