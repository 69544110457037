function getModifiedOfferItems(offerItems) {
	let modifiedOfferItems = offerItems.map((item) => ({
		...item,
		offerSelected: item.offerSelected ?? false,
	}));

	modifiedOfferItems.sort(
		(a, b) =>
			Number(a.minim_amount_to_be_purchased) -
			Number(b.minim_amount_to_be_purchased)
	);

	// when considering multiple offers remove this part
	modifiedOfferItems =
		modifiedOfferItems.length > 0 ? [modifiedOfferItems[0]] : [];
	//

	return modifiedOfferItems;
}

function getOfferRelatedCustomerMessage(offer, offerEnablingBalanceAmount) {
	let offerMessage = "";
	if (Number(offerEnablingBalanceAmount) === 0) {
		offerMessage = offer.offer_message;
	} else {
		offerMessage = `You are $${offerEnablingBalanceAmount} away from getting an offer`;
	}
	return offerMessage;
}

function getOfferEnablingBalanceAmount(offer, cartTotalItemsAmount) {
	let balanceAmount =
		Number(offer.minim_amount_to_be_purchased) - Number(cartTotalItemsAmount);

	balanceAmount = balanceAmount > 0 ? balanceAmount : 0;

	balanceAmount = balanceAmount.toFixed(2);
	return balanceAmount;
}

function getCartOfferList(params) {
	const { offers, cartTotalItemsAmount } = params;
	let modifiedOffers = [];
	for (let i = 0; i < offers.length; i++) {
		const offer = offers[i];
		let modifiedOffer = {};
		let offerReadyToSelect = false;
		let offerEnablingBalanceAmount = getOfferEnablingBalanceAmount(
			offer,
			cartTotalItemsAmount
		);
		let offerRelatedCustomerMessage = getOfferRelatedCustomerMessage(
			offer,
			offerEnablingBalanceAmount
		);
		if (Number(offerEnablingBalanceAmount) === 0) {
			offerReadyToSelect = true;
		}
		modifiedOffer = {
			...offer,
			offerEnablingBalanceAmount,
			offerRelatedCustomerMessage,
			offerReadyToSelect,
		};

		modifiedOffers.push(modifiedOffer);
	}

	return modifiedOffers;
}

async function getRoadDistance(
	originLat,
	originLng,
	destinationLat,
	destinationLng,
	callback
) {
	var origin = new window.google.maps.LatLng(originLat, originLng);
	var destination = new window.google.maps.LatLng(destinationLat, destinationLng);

	var service = new window.google.maps.DistanceMatrixService();
	service.getDistanceMatrix(
		{
			origins: [origin],
			destinations: [destination],
			travelMode: "DRIVING",
			// avoidHighways: false,
			// avoidTolls: false,
		},
		callback
	);
}

function getRestaurantDeliveryShare(profile, deliveryTotal, isFree = false) {
	let restaurantFixedShare = Number(
		profile.dynamic_delivery_pay_configuration.restaurant_fixed_delivery_share
	);

	let restaurantPercentageShare = Number(
		profile.dynamic_delivery_pay_configuration
			.restaurant_percentage_delivery_share
	);

	if (isFree) {
		restaurantFixedShare = 0;
		restaurantPercentageShare = 100;
	}

	if (restaurantPercentageShare >= 100) {
		restaurantPercentageShare = 100;
		restaurantFixedShare = 0;
	}
	let totalRestaurantShare =
		restaurantFixedShare + (restaurantPercentageShare * deliveryTotal) / 100;

	if (totalRestaurantShare > deliveryTotal) {
		totalRestaurantShare = deliveryTotal;
	}
	return totalRestaurantShare;
}

function getDynamicDeliveryTotal(
	profile,
	isFree,
	only_restaurant_share = false,
	distance
) {
	distance = Number(distance);
	let fixed_km_for_dd_pay = Number(profile.fixed_km_for_dd_pay);
	let fixed_delivery_fee_for_dd_pay = Number(
		profile.fixed_delivery_fee_for_dd_pay
	);
	let delivery_fee_per_km_for_dd_pay = Number(
		profile.delivery_fee_per_km_for_dd_pay
	);

	let deliveryCharge = 0;

	if (distance <= fixed_km_for_dd_pay) {
		deliveryCharge = fixed_delivery_fee_for_dd_pay;
	} else {
		deliveryCharge =
			fixed_delivery_fee_for_dd_pay +
			(distance - fixed_km_for_dd_pay) * delivery_fee_per_km_for_dd_pay;
	}

	let totalRestaurantShare = getRestaurantDeliveryShare(
		profile,
		deliveryCharge,
		isFree
	);
	deliveryCharge = deliveryCharge - totalRestaurantShare;

	deliveryCharge = deliveryCharge > 0 ? deliveryCharge : 0;

	if (only_restaurant_share) {
		return totalRestaurantShare.toFixed(2);
	}

	return deliveryCharge.toFixed(2);
}

//

function getVisibleDeliveryTotal(profile, mode, deliveryTotalAmount) {
	let deliveryTotal = deliveryTotalAmount;
	let deliveryServiceFee = 0;
	try {
		if (
			profile.is_stripe_connect_enabled &&
			profile.is_dynamic_delivery_pay_transfer_enabled &&
			mode == 0
		) {
			let displayLimit = Number(profile.minimum_displayable_limit_for_dd_pay);
			if (deliveryTotal >= displayLimit) {
				let amount_after_display_limit = deliveryTotal - displayLimit;
				deliveryTotal = displayLimit;
				if (amount_after_display_limit > 0) {
					let deliveryServiceFeePercent = Number(
						profile.delivery_service_percentage_for_dd_pay
					);
					deliveryServiceFee =
						(amount_after_display_limit * deliveryServiceFeePercent) /
						100;
					deliveryTotal =
						deliveryTotal +
						amount_after_display_limit -
						deliveryServiceFee;
				}
			}
			if (isNaN(deliveryServiceFee)) {
				throw new Error("Delivery service fee is not a number");
			}

			if (isNaN(deliveryTotal)) {
				throw new Error("Delivery fee is not a number");
			}
		}
	} catch (e) {
		deliveryServiceFee = 0;
		deliveryTotal = deliveryTotalAmount;
	}

	return {
		deliveryTotal: deliveryTotal.toFixed(2),
		deliveryServiceFee: deliveryServiceFee.toFixed(2),
	};
}
//

export {
	getModifiedOfferItems,
	getCartOfferList,
	getRoadDistance,
	getDynamicDeliveryTotal,
	getVisibleDeliveryTotal,
};
