import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";

import {
	Row,
	Col,
	Container,
	Form,
	InputGroup,
	Button,
	Tab,
	Nav,
	ButtonToolbar,
	ToggleButton,
	ToggleButtonGroup,
	Image,
	OverlayTrigger,
	Tooltip,
	Badge,
	ThemeProvider,
	Card,
	Alert,
	Spinner,
} from "react-bootstrap";
import ItemsCarousel from "./common/ItemsCarousel";
import ChooseAddressCard from "./common/ChooseAddressCard";
import CheckoutItem from "./common/CheckoutItem";
import AddAddressModal from "./modals/AddAddressModal";
import FontAwesome from "./common/FontAwesome";
import Icofont from "react-icofont";
import FacebookLogin from "react-facebook-login";
import DataService from "../services/DataService";
import GoogleLogin from "react-google-login";
import SearchLocationInput from "./location/SearchLocationInput";
import SimpleReactValidator from "simple-react-validator";
import { alertCreation, alertError } from "../components/Alerts";
import { createBrowserHistory } from "history";
import { render } from "react-dom";
import ViewMenuDiscriptionModel from "../components/modals/ViewMenuDiscriptionModal";
import Moment from "moment";
import { currentURL, ONLINE_PAYMENT_MODES } from "../helpers/globalConstants";
import moment from "moment";
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
import StripeCheckoutButton from "./stripe/StripeCheckoutButton";
import {
	getCartOfferList,
	getDynamicDeliveryTotal,
	getVisibleDeliveryTotal,
} from "../helpers/helpers";
import FreeOfferItemsSelectionModal from "./modals/FreeOfferItemsSelectionModal";
import { loadStripe } from "@stripe/stripe-js";
import {
	Elements,
	ElementsConsumer,
	ExpressCheckoutElement,
	PaymentElement,
} from "@stripe/react-stripe-js";
import OfferEngine, {
	createCartItemsOfferPairs,
	resetCart,
} from "../services/OfferEngine";
import TermsAndConditionsLink from "./common/TermsAndConditions";
import StripeForm from "./common/StripeForm";

const orderString = ["Delivery Order", "Takeout Order"];
const paymentStrings = ["Pay on Delivery", "Pay at Store"];

const history = createBrowserHistory();
var myCheckout;

class Checkout extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.dataHandler = new DataService("restaurants");
		let key_name = this.props.key_name;

		let cart = this.dataHandler.getCart(key_name);
		let offers = this.dataHandler.getOffers(key_name);
		let cartParams = this.dataHandler.getCartParams(key_name);
		var applied_coupens = [];

		if (cartParams != false) {
			if (cartParams.customer) {
				var sessionCustomer = JSON.parse(cartParams.customer);
				console.log("Cartsession", sessionCustomer);

				if (sessionCustomer) {
					applied_coupens =
						sessionCustomer && sessionCustomer.applied_coupens
							? JSON.parse(sessionCustomer.applied_coupens)
							: [];
				}
			}
		}
		console.log("cart frpm db", cart);

		let inputParams = this.dataHandler.getCartParams(this.props.key_name);
		let tipPercentage = 10;

		let cust_id = this.dataHandler.getCustomerID(key_name);
		let profile = this.dataHandler.getRestaurantProfile(key_name).profile;
		if (
			this.dataHandler.getDeleveryMode(key_name) == 1 &&
			profile.default_takeout_tip
		) {
			tipPercentage = Number(profile.default_takeout_tip);
		} else if (
			this.dataHandler.getDeleveryMode(key_name) == 0 &&
			profile.default_delivery_tip
		) {
			tipPercentage = Number(profile.default_delivery_tip);
		}
		const stripePromise = loadStripe(profile.stripe_key);
		this.categories =
			this.dataHandler.getRestaurantProfile(key_name).categories;
		this.menu = this.dataHandler.getRestaurantProfile(key_name).menu;
		let coupens = this.dataHandler.getRestaurantProfile(key_name).coupens;
		console.log("Coupens", coupens);
		// 7start
		let deliveryCharge = profile.delevery_charge;
		let deleveryFree = profile.delevery_free;
		if (profile.enable_draw_delivery_zone) {
			let zone = this.dataHandler.getZone(key_name);
			if (zone) {
				deliveryCharge = zone.delivery_charge;
				deleveryFree = zone.delivery_free_min_order_value;
				profile["delevery_enabling_charge"] =
					zone.delivery_enabling_order_value;
				profile["enable_delevery_free"] = zone.enable_delivery_free;
			}
		} else {
			deliveryCharge = profile.delevery_charge;
			let distance = this.dataHandler.getDistance(key_name);

			deleveryFree = profile.delevery_free;

			if (distance) {
				var num_distance = Number(distance);
				console.log("fetchd distance", num_distance);

				if (
					num_distance > profile.minim_delevery &&
					num_distance <= profile.maxim_delevery
				) {
					console.log("maxim zone");
					deliveryCharge = profile.max_delevery_charge;
					deleveryFree = profile.max_delevery_free;
				}
			}
		}
		// 7end
		let taxEnabled = profile.tax_enabled;
		this.modeOfDelevery = this.dataHandler.getDeleveryMode(key_name);
		this.state = {
			showAddressModal: false,
			showHideDelivery: false,
			cart: cart,
			offers: offers,
			Alertvisible: false,
			alertMessage: "",

			max: 50,
			key_name: key_name,
			min: 1,
			menu: this.menu,
			validCart: false,
			profile: profile,
			stripePromise,
			showStripeForm: false,
			stripeLoading: false,
			stripeError: null,
			// stripeCreatedCart: null,
			stepStage: 0,
			tipPercentage: tipPercentage,
			appliedCoupens: applied_coupens,
			allCoupens: coupens,
			steps: [true, false, false],
			login_details: {
				verification: false,
				payment_mode: "PAY ONLINE",
				...(!tipPercentage ? { tip: 0 } : {}),
			},
			delevery_details: {},
			modeOfDelevery: this.modeOfDelevery,
			deleveryForm: {},
			showMenuProfile: false,
			cust_id: this.dataHandler.getCustomerID(key_name),
			paymentTerms: {
				taxEnabled: taxEnabled,
				deliveryFree: deleveryFree,
				deliveryCharge: deliveryCharge,
			},
			showFreeOfferItemSelectionModal: false,
			selectedOffer: null,
			skipOfferSelection: false,
			offerRules: this.dataHandler.getOfferRules(key_name) || [],
			roadDistance: this.dataHandler.getRoadDistance(key_name),
		};
		this.hideDelivery = this.hideDelivery.bind(this);
		this.deliveryModes = ["is_delivery_enabled", "is_takeout_enabled"];
		this.onClickUpCellItem = this.onClickUpCellItem.bind(this);
		this.incrementItem.bind(this);
		this.decreaseItem.bind(this);
		this.responseFacebook = this.responseFacebook.bind(this);
		this.responseGoogle = this.responseGoogle.bind(this);
		this.toggleStep = this.toggleStep.bind(this);
		this.loginFormHandler = this.loginFormHandler.bind(this);
		this.setPayment = this.setPayment.bind(this);
		this.setStripePayment = this.setStripePayment.bind(this);
		this.setStripePaymentUsingIntent =
			this.setStripePaymentUsingIntent.bind(this);
		this.confirmStripePayment = this.confirmStripePayment.bind(this);
		this.emailIssue = this.emailIssue.bind(this);
		this.setStripeError = this.setStripeError.bind(this);
		this.setStripeLoading = this.setStripeLoading.bind(this);
		this.setStripeFormShow = this.setStripeFormShow.bind(this);
		this.setDeliveryAddress = this.setDeliveryAddress.bind(this);
		this.addCart = this.addCart.bind(this);
		this.finishDelivery = this.finishDelivery.bind(this);
		this.setCustomaize = this.setCustomaize.bind(this);
		this.getMonerisPage = this.getMonerisPage.bind(this);

		this.toggleStep();

		this.recomandedItems = this.getUpCellItems();
		this.skipStep = this.skipStep.bind(this);
		this.applyRuleBasedOffers = this.applyRuleBasedOffers.bind(this);
	}
	// offers rules source/dest

	applyRuleBasedOffers = () => {
		let cartCopy = [...this.state.cart];
		try {
			const offerRules = this.state.offerRules;

			if (offerRules?.length && cartCopy?.length) {
				// cartCopy = attachCategoryIdToCartItems(
				// 	this.state.categoryFoodItemMap,
				// 	cartCopy
				// );
				const offerEngine = new OfferEngine(offerRules, [
					...this.state.cart,
				]);
				cartCopy = offerEngine.applyOffers(cartCopy);

				this.setState({ offerRules: offerEngine.getUpdatedOfferRules() });
				this.dataHandler.setOfferRules(
					this.state.key_name,
					offerEngine.getUpdatedOfferRules()
				);
			}
		} catch (e) {
			cartCopy = resetCart([...this.state.cart]);
		}
		this.setState({ cart: cartCopy });
		this.dataHandler.setCart(this.state.key_name, cartCopy);
	};
	//
	isInMenu(foodId) {
		if (!this.state.menu) {
			return false;
		}
		const DELETE = 1;
		const LIVE = 0;
		let status = false;
		const found = this.state.menu.find((element) => element.item === foodId);
		if (typeof found != "undefined") {
			status = found.delete_status == LIVE;
		}

		return status;
	}

	getUpCellItems = () => {
		let upCellItems = [];
		let allCategories = this.categories;
		if (allCategories) {
			allCategories.map((catObj) => {
				catObj.foods_displayed_in_menu =
					catObj.foods_displayed_in_menu.filter((foodObj) =>
						this.isInMenu(foodObj.id)
					);
				catObj.foods_displayed_in_menu.map((foodObj, idx) => {
					let modeString = this.deliveryModes[this.state.modeOfDelevery];

					if (
						foodObj[modeString] == true &&
						foodObj.upcell_enabled == true &&
						upCellItems.indexOf(foodObj) == -1
					) {
						upCellItems.push(foodObj);
					}
				});
			});
		}
		console.log("Upcell Items", upCellItems);
		return upCellItems;
	};
	toggleStep = (
		stageNumber,
		callBack = () => {
			console.log("d");
		},
		callBacKPara = 0
	) => {
		stageNumber = !stageNumber ? this.state.stepStage : stageNumber;
		let steps = [false, false, false];
		steps[stageNumber] = true;
		this.setState({ steps: steps }, () => {
			callBack(callBacKPara);
		});
	};

	setStripePayment = (paymentToken, key_pay = "payment_details_stripe") => {
		let inputForm = { ...this.state.login_details };
		inputForm["payment_mode"] = "PAY ONLINE";
		inputForm["payment_status"] = 1;
		inputForm[key_pay] = paymentToken;
		this.setState({ login_details: inputForm }, () => {
			this.finishDelivery();
		});
	};

	setStripeLoading = (value) => {
		this.setState({ stripeLoading: value });
	};
	setStripeError = (error) => {
		this.setState({ stripeError: error });
		if (error) {
			this.setStripeLoading(false);
		}
	};
	setStripeFormShow = (value) => {
		this.setStripeError(null);
		this.setStripeLoading(false);
		// this.setState({ stripeCreatedCart: null });
		if (!value) {
			this.setState({ showStripeForm: value });
		} else {
			if (this.validator.allValid() == true) {
				this.processCart();
				this.setState({ showStripeForm: value });
			} else {
				this.validator.showMessages();
			}
		}
	};

	emailIssue = (mail_params) => {
		new DataService("carts").emailClientIssue(mail_params);
	};

	confirmStripePayment = async (params) => {
		const { stripe, elements, clientSecret, id } = params;
		try {
			const result = await stripe.confirmPayment({
				//`Elements` instance that was used to create the Payment Element
				elements,
				clientSecret: clientSecret,
				confirmParams: {
					return_url:
						currentURL +
						this.state.key_name +
						"/payment/" +
						id +
						"/" +
						"verify",
				},
				redirect: "if_required",
			});

			if (result.error) {
				// console.log(result.error);
				// Show error to your customer (for example, payment details incomplete)
				this.setStripeError(
					result.error?.message ?? "An error occured on payment"
				);
				// console.log(result.error);
			} else {
				new DataService("updateOrder")
					.createData({
						action_type: "update_order_payment_status",
						payment_status: 1,
						cart_id: id,
					})
					.then((response) => {
						if (
							response.data["status"] === "Sucess" ||
							response.data["status"] === "success"
						) {
							window.location =
								currentURL +
								this.state.key_name +
								"/payment/" +
								id +
								"/" +
								"verified";
						} else {
							window.location =
								currentURL +
								this.state.key_name +
								"/payment/" +
								id +
								"/" +
								"verify";
						}
					})
					.catch((err) => {
						window.location =
							currentURL +
							this.state.key_name +
							"/payment/" +
							id +
							"/" +
							"verify";
					});

				// console.log("successssssssssssssss");
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
			}
		} catch (er) {
			this.setStripeError("An error occured on payment");
			this.emailIssue({
				restaurant_name: this.props.key_name,
				issue_type: 1,
				issue_message:
					"Process Failed for" +
					"payment confirmation" +
					"Order details : cart id:" +
					id,
			});
		}
	};

	setStripePaymentUsingIntent = (params) => {
		this.processCart();
		// if (this.state.stripeCreatedCart) {
		// 	this.confirmStripePayment({
		// 		...params,
		// 		clientSecret: this.state.stripeCreatedCart.clientSecret,
		// 		id: this.state.stripeCreatedCart.id,
		// 	});
		// } else {
		let inputParams = new DataService("carts").getCartParams(
			this.props.key_name
		);

		let customerParams = JSON.parse(inputParams.customer);
		var pay_message = "payment confirmation pending order";

		if (customerParams) {
			customerParams["payment_status"] = 0;
			customerParams["payment_mode"] = "PAY ONLINE";
			customerParams["payment_details_stripe"] = true;

			customerParams["payment_details"] = 0;
			inputParams.customer = JSON.stringify(customerParams);

			new DataService("carts")
				.createCart(inputParams)
				.then((response) => {
					if (response.data["status"] === "Sucess") {
						// this.setState({
						// 	stripeCreatedCart: {
						// 		id: response.data?.data?.id,
						// 		clientSecret:
						// 			response.data?.data?.stripe_response
						// 				?.client_secret,
						// 	},
						// });
						this.confirmStripePayment({
							...params,
							clientSecret:
								response.data?.data?.stripe_response?.client_secret,
							id: response.data?.data?.id,
						});
					} else {
						this.setStripeError("An error occured on order creation");
						this.emailIssue({
							restaurant_name: this.props.key_name,
							issue_type: 1,
							issue_message:
								"Stripe payment process Failed for" +
								"cart creation" +
								"Order details :" +
								JSON.stringify(inputParams) +
								" Response : -> " +
								JSON.stringify(response.data),
						});
					}
				})
				.catch((err) => {
					this.setStripeError("An error occured on order creation");
					this.emailIssue({
						restaurant_name: this.props.key_name,
						issue_type: 1,
						issue_message:
							"Payment transaction failed at client on " +
							"cart creation" +
							"Order details :" +
							JSON.stringify(inputParams) +
							" Response : -> " +
							err.toString(),
					});
				});
		}
		// }
	};

	setPayment = (paymentMode) => {
		let stateDic = {};
		let inputForm = { ...this.state.login_details };
		inputForm["payment_mode"] = paymentMode;
		if (paymentMode === "Pay at Store") {
			stateDic["tipPercentage"] = 0;
			inputForm["tip"] = 0;
			stateDic["login_details"] = inputForm;
			this.setState(stateDic, () => {
				this.validateCart();
				this.processCart();
				this.finishDelivery();
			});
		} else {
			this.setState({ login_details: inputForm }, () => {
				this.finishDelivery();
			});
		}
	};
	loginFormHandler = (event) => {
		let key = "";
		let value = "";
		let stateDic = {};
		let inputForm = { ...this.state.login_details };

		if (
			event.target.type == "text" ||
			"email" ||
			event.target.type == "textarea" ||
			event.target.type == "number"
		) {
			key = event.target.name;
			value = event.target.value;
			if (key == "tip") {
				value = Number(value) > 0 ? value : 0;
				stateDic["tipPercentage"] = 0;
			}
		} else if (event.target.type == "checkbox") {
			key = event.target.name;
			value = event.target.checked;
		} else if (event.target.type == "file") {
			key = event.target.name;
			value = event.target.files[0];
		}
		if (key) {
			inputForm[key] = value;
		}
		stateDic["login_details"] = inputForm;

		this.setState(stateDic, () => {
			this.validateCart();
			this.processCart();
		});
	};
	removeTip() {
		let inputForm = { ...this.state.login_details };
		console.log("Before tip", inputForm);
		if ("tip" in inputForm) {
			delete inputForm["tip"];
		}
		console.log("after  tip", inputForm);

		this.setState({ login_details: inputForm }, () => {
			this.processCart();
		});
	}
	deleveryFormHandler = (event) => {
		let key = "";
		let value = "";

		let inputForm = { ...this.state.deleveryForm };

		if (event.target.type == "text" || event.target.type == "textarea") {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type == "file") {
			key = event.target.name;
			value = event.target.files[0];
		}

		inputForm[key] = value;
		this.setState({ deleveryForm: inputForm });
		this.validateCart();
	};

	updateOffers = (offers, cart) => {
		let inputCart = [...cart];
		let modifiedOffers = [...offers];
		// offfer removal part
		// let removedOffers = [];
		try {
			offers.map((item) => {
				if (!item.offerReadyToSelect && item.offerSelected) {
					inputCart = inputCart.filter(
						(cartItem) => cartItem.appliedOfferId !== item.id
					);

					modifiedOffers = modifiedOffers.map((offerItem) => {
						if (item.id === offerItem.id) {
							return { ...offerItem, offerSelected: false };
						}
						return offerItem;
					});
				} else if (
					item.offerSelected &&
					!inputCart.some(
						(cartItem) => cartItem.appliedOfferId === item.id
					)
				) {
					modifiedOffers = modifiedOffers.map((offerItem) => {
						if (item.id === offerItem.id) {
							return { ...offerItem, offerSelected: false };
						}
						return offerItem;
					});
				}
			});
		} catch (error) {}

		this.setState({ cart: inputCart }, () => this.applyRuleBasedOffers());
		// this.dataHandler.setCart(this.state.key_name, inputCart);

		this.setState({ offers: modifiedOffers });
		this.dataHandler.setOffers(this.state.key_name, modifiedOffers);
		if (this.state.showStripeForm) {
			this.processCart();
		}
	};

	updateOffersList = ({ cart, offers }) => {
		let modifiedOffers = [];
		try {
			let cartSubTotal = cart.reduce(
				(total, item) => (total += +item.price * item.quantity),
				0
			);
			cartSubTotal = cartSubTotal.toFixed(2);
			modifiedOffers = getCartOfferList({
				offers: offers ?? this.state.offers,
				cartTotalItemsAmount: cartSubTotal,
			});
		} catch (error) {
			modifiedOffers = [];
		}
		this.updateOffers(modifiedOffers, cart);
	};

	unSelectOfferItem = (offerId) => {
		let modifiedOffers = [...this.state.offers].map((item) => {
			if (item.id === offerId) {
				return { ...item, offerSelected: false };
			}
			return item;
		});
		return modifiedOffers;
	};

	selectOfferItem = (offerId) => {
		let modifiedOffers = [...this.state.offers].map((item) => {
			if (item.id === offerId) {
				return { ...item, offerSelected: true };
			}
			return item;
		});
		return modifiedOffers;
	};

	setQty = (cartItem, idx) => {
		let modifiedOffers;
		let inputCart = [...this.state.cart];
		if (cartItem.quantity == 0) {
			inputCart.splice(idx, 1);
			if (
				cartItem.appliedOfferId &&
				inputCart.filter(
					(item) => item.appliedOfferId === cartItem.appliedOfferId
				).length <= 0
			) {
				modifiedOffers = this.unSelectOfferItem(cartItem.appliedOfferId);
			}
		} else {
			inputCart[idx].quantity = cartItem.quantity;
			inputCart[idx].discount = cartItem.discount;
		}

		this.updateOffersList({ cart: inputCart, offers: modifiedOffers ?? null });
	};

	getTipInPercentage = (tipPercentage = 10) => {
		let Total = this.getGrandTotal();
		let tip = Number((Total * this.state.tipPercentage) / 100).toFixed(2);
		let inputForm = { ...this.state.login_details };
		inputForm["tip"] = tip;
		this.setState({ login_details: inputForm });
	};

	findTipPercentage = (ctip) => {
		//let Total = this.getGrandTotal();
		//let tip=this.state.login_details["tip"]
		//let tipPercentage=(tip*100/Total).toFixed(0);

		if (this.state.tipPercentage == ctip) {
			return "border-none badge-success ml-1";
		} else {
			return "border-none badge-white myfont1 ml-1";
		}
	};
	hideMenuProfile = () => this.setState({ showMenuProfile: false });

	getCoupens = (applied) => {
		var all = [...this.state.allCoupens];
		if (this.state.modeOfDelevery == 0) {
			//delivery mode is delicvery
			all = all.filter((cobj) => cobj.enable_delivery == true);
		} else {
			all = all.filter((cobj) => cobj.enable_takeout == true);
		}
		console.log("All coupend", all);
		var total = this.getCartSubTotal();
		var blns = [];
		if (applied == false) {
			blns = all.filter(
				(cobj) => this.state.appliedCoupens.indexOf(cobj.id) == -1
			);
		} else {
			blns = all.filter(
				(cobj) =>
					this.state.appliedCoupens.indexOf(cobj.id) != -1 &&
					Number(cobj.enble_order_limit) <= total
			);
		}
		return blns;
	};
	valueCoupen = (coupen) => {
		var coupenDiscount = Number(coupen.coupen_discount);
		var total = this.getCartSubTotal();
		if (coupen.enable_percentage == true) {
			coupenDiscount = Number(
				(this.getCartSubTotal() * coupenDiscount) / 100
			).toFixed(2);
		}
		return coupenDiscount;
	};
	matchCoupenCode = () => {
		var coupenCode = this.state.coupenCode;
		var status = false;
		var alertMessage = "Coupon expired or Invalid coupon code!";
		var appliableCopen = this.getCoupens(false);
		var total = this.getCartSubTotal();
		var alreadyApplied = [...this.state.appliedCoupens];
		var cid = 0;
		appliableCopen.map((cobj) => {
			var limit = Number(cobj.enble_order_limit);
			console.log("limit", limit);
			console.log("objcopencode", cobj.coupen_code);
			console.log("entredCope", coupenCode);
			if (cobj.coupen_code.toLowerCase() == coupenCode.toLowerCase()) {
				if (limit <= total) {
					cid = cobj.id;
				} else {
					alertMessage = "Lower than coupon redeem value";
				}
			}
		});
		if (cid != 0) {
			alertMessage = "Coupon applied successfully!";
			alreadyApplied.push(cid);
			this.setState(
				{
					appliedCoupens: alreadyApplied,
					Alertvisible: true,
					alertColor: "success",
					alertMessage: alertMessage,
					coupenCode: "",
				},
				() => {
					this.processCart();
					// window.setTimeout(()=>{this.setState({Alertvisible:false})},1000);
				}
			);
		} else {
			this.setState(
				{
					appliedCoupens: alreadyApplied,
					Alertvisible: true,
					alertColor: "danger",
					alertMessage: alertMessage,
					coupenCode: "",
				}
				//()=> //{window.setTimeout(()=>{this.setState({Alertvisible:false})},500)
				//}
			);
		}
	};
	validateCoupens = (total) => {
		var status = false;
		var appliedCoupens = this.getCoupens(true);
		var alreadyApplied = [];
		alreadyApplied = appliedCoupens.map((cobj) => {
			var limit = Number(cobj.enble_order_limit);
			if (limit <= total) {
				return cobj.id;
			}
		});

		this.setState({ appliedCoupens: alreadyApplied });
		return true;
	};
	getTaxTotal = (amount) => {
		if (this.state.profile.appliicable_tax) {
			let applicableTax = this.state.profile.appliicable_tax;
			let taxGroup = applicableTax.title;
			let taxArray = applicableTax.added_taxes.map((taxSplit) => {
				return {
					name: taxSplit.title,
					percentage: taxSplit.tax_precentage,
					amount: ((taxSplit.tax_precentage * amount) / 100).toFixed(2),
				};
			});
			return taxArray;
		} else {
			return [];
		}
	};

	getTaxGroupTotal = (amount) => {
		const { appliicable_tax, delivery_tax_enabled, delivery_appliicable_tax } =
			this.state.profile;

		if (!appliicable_tax) {
			return {
				name: "NA",
				percentage: "NA",
				amount: 0,
			};
		}

		const taxGroup = appliicable_tax.title;
		let taxableAmount = 0;

		const taxPercentage = appliicable_tax.added_taxes.reduce(
			(total, taxSplit) => total + Number(taxSplit.tax_precentage),
			0
		);

		if (delivery_tax_enabled) {
			const deliveryAmount = Number(
				getVisibleDeliveryTotal(
					this.state.profile,
					this.state.modeOfDelevery,
					Number(this.getDeliveryTotal())
				).deliveryTotal
			);
			amount = Number(amount) - deliveryAmount;
			const deliveryTaxPercentage =
				delivery_appliicable_tax.added_taxes.reduce(
					(total, taxSplit) => total + Number(taxSplit.tax_precentage),
					0
				);
			taxableAmount += Number(
				((deliveryTaxPercentage * deliveryAmount) / 100).toFixed(2)
			);
		}
		taxableAmount += Number(((taxPercentage * amount) / 100).toFixed(2));
		const result = {
			name: taxGroup,
			percentage: taxPercentage,
			amount: taxableAmount,
		};
		return result;
	};

	calculateExtraCharge = (extra, amount) => {
		var extraAmount = 0;
		if (extra.is_application_shared_fee == true) {
			var percentage = parseFloat(extra.percentage_share * amount) / 100.0;
			//percentage=(percentage)?percentage.toFixed(2):0
			extraAmount = (percentage + parseFloat(extra.fixed_share)).toFixed(2);
			console.log(
				extra.percentage_share,
				amount,
				percentage,
				extra.fixed_share,
				extraAmount
			);
		} else {
			extraAmount =
				extra.enable_percentage == true
					? ((extra.value * amount) / 100.0).toFixed(2)
					: extra.value;
		}
		if (extraAmount != 0) {
			console.log(extra.title, extraCharge);
			var extraCharge = {
				name: extra.title,
				value: extra.value,
				enable_percentage: extra.enable_percentage,
				valueAmount: extraAmount,
				id: extra.id,
				is_application_shared_fee: extra.is_application_shared_fee,
				taxable: extra.taxable,
			};
			return extraCharge;
		} else {
			return false;
		}
	};
	getExtraChargeTotal = (amount) => {
		var extraAmount = 0;
		if (
			this.state.profile.extra_payments &&
			this.state.profile.extra_payments.length > 0
		) {
			console.log(this.state.profile.extra_payments);
			let extraCharges = [];
			this.state.profile.extra_payments.map((extra) => {
				if (extra.is_visible_on_invoice == true) {
					if (
						(this.state.modeOfDelevery == 0 &&
							extra.enable_delivery == true) ||
						(this.state.modeOfDelevery == 1 &&
							extra.enable_takeout == true)
					) {
						extraAmount = this.calculateExtraCharge(extra, amount);
						if (extraAmount) {
							extraCharges.push(extraAmount);
						}
					}
				}
			});
			console.log("Extra Charges", extraCharges);
			return extraCharges;
		} else {
			return [];
		}
	};
	getPayableTotal = () => {
		let tip =
			this.state.tipPercentage != 0
				? Number(
						(this.getGrandTotal() * this.state.tipPercentage) / 100
				  ).toFixed(2)
				: this.state.login_details["tip"];
		let payAbleTotal = Number(this.getGrandTotal()) + Number(tip);
		payAbleTotal = payAbleTotal.toFixed(2);
		return payAbleTotal;
	};

	getExtraTotal = (taxable = true) => {
		var xtotal = 0;
		var extraCharges = this.getExtraChargeTotal(Number(this.getCartTotal()));
		if (extraCharges.length > 0) {
			extraCharges.map((eobj) => {
				if (eobj.taxable == taxable) {
					if (eobj.valueAmount) {
						xtotal = xtotal + Number(eobj.valueAmount);
					}
				}
			});
		}
		return Number(xtotal);
	};
	getGrandTotal = () => {
		let grandTotal = 0;
		//calculate subtotal
		grandTotal = this.getCartTotal();

		//calculate extra charge

		var xtotal = Number(this.getExtraTotal());

		grandTotal = Number(grandTotal) + xtotal;
		console.log("grand total", grandTotal);
		if (this.state.profile.tax_enabled) {
			let taxes = this.getTaxGroupTotal(grandTotal);
			let totalTax = taxes.amount;
			grandTotal = Number(grandTotal) + Number(totalTax);
		}
		grandTotal = grandTotal + Number(this.getExtraTotal(false));
		return Number(grandTotal).toFixed(2);
	};

	getCartSubTotal = () => {
		let cartSubTotal = this.state.cart.reduce(
			(total, item) =>
				(total +=
					+item.price * item.quantity -
					parseFloat(item.totalOfferDiscount || 0)),
			0
		);
		cartSubTotal = cartSubTotal.toFixed(2);
		return cartSubTotal;
	};
	getDiscountTotal = (ignoreTakeoutDiscount = false) => {
		let discountTotal = this.state.cart.reduce(
			(total, item) =>
				(total +=
					Number((item.price * item.discount) / 100) * item.quantity),
			0
		);
		console.log("appliedDiscountBeforee", discountTotal);
		discountTotal = discountTotal;

		if (ignoreTakeoutDiscount == false) {
			discountTotal =
				discountTotal + Number(this.getTakeoutDiscountPrecentage());
		}
		console.log("applied discount", discountTotal);
		return discountTotal;
	};
	getTakeoutDiscountPrecentage = () => {
		if (this.state.modeOfDelevery == 0) {
			return 0;
		}
		if (this.state.profile.enable_takeout_discount == true) {
			var blnce = this.getTakeoutDiscount();

			if (blnce == 0) {
				let TotalAmount =
					Number(this.getCartSubTotal()) -
					Number(this.getDiscountTotal(true));
				console.log("Discount Total amount", TotalAmount);
				console.log("Discount %", this.state.profile.take_out_discount);

				console.log("Discount Total amount", TotalAmount);

				var discount = Number(
					(TotalAmount * Number(this.state.profile.take_out_discount)) /
						100
				).toFixed(2);
				return discount;
			}
		}

		return 0;
	};
	getCoupenDiscount = () => {
		var coupens = this.getCoupens(true);
		var coupenDiscount = 0;
		console.log("Coupens for Discount", coupens);
		coupens.map((cobj) => {
			coupenDiscount = coupenDiscount + Number(this.valueCoupen(cobj));
		});
		return coupenDiscount;
	};

	getDeliveryTotal = (only_restaurant_share = false) => {
		if (this.state.modeOfDelevery == 1) {
			return 0;
		}
		let isFree = false;
		let TotalAmount =
			this.getCartSubTotal() -
			(this.getDiscountTotal() + this.getCoupenDiscount());
		// 7start change
		let deliveryCharge =
			TotalAmount == 0 ||
			(TotalAmount >= this.state.paymentTerms["deliveryFree"] &&
				this.state.profile.enable_delevery_free === true)
				? 0
				: this.state.paymentTerms["deliveryCharge"];

		if (
			this.state.profile.is_stripe_connect_enabled &&
			this.state.profile.is_dynamic_delivery_pay_transfer_enabled &&
			this.state.modeOfDelevery == 0
		) {
			isFree = deliveryCharge <= 0 ? true : false;
			return getDynamicDeliveryTotal(
				this.state.profile,
				isFree,
				only_restaurant_share,
				this.state.roadDistance
			);
		}

		if (only_restaurant_share) {
			return "0.00";
		}
		return Number(deliveryCharge).toFixed(2);
	};
	getDeliveryFree = () => {
		let balance = 0;
		if (this.state.modeOfDelevery == 1) {
			return balance;
		}
		let TotalAmount = this.getCartSubTotal() - this.getDiscountTotal();
		console.log("total", TotalAmount);
		if (TotalAmount > 0) {
			balance = this.state.paymentTerms["deliveryFree"] - TotalAmount;
			if (
				Number(
					getVisibleDeliveryTotal(
						this.state.profile,
						this.state.modeOfDelevery,
						Number(this.getDeliveryTotal())
					).deliveryTotal
				) <= 0 &&
				this.state.profile.is_stripe_connect_enabled &&
				this.state.profile.is_dynamic_delivery_pay_transfer_enabled &&
				this.state.modeOfDelevery == 0
			) {
				balance = 0;
			}
			balance = balance > 0 ? Number(balance).toFixed(2) : 0;
		}
		console.log("delivery fee :", balance);
		return balance;
	};
	getTakeoutDiscount = () => {
		let balance = 0;
		if (this.state.modeOfDelevery == 0) {
			return balance;
		}
		let TotalAmount = this.getCartSubTotal() - this.getDiscountTotal(true);
		if (TotalAmount > 0) {
			balance =
				this.state.profile.take_out_discount_minim_value - TotalAmount;
			balance = balance > 0 ? Number(balance).toFixed(2) : 0;
		}
		return balance;
	};

	getCartTotal = () => {
		let totalDeliveryData = getVisibleDeliveryTotal(
			this.state.profile,
			this.state.modeOfDelevery,
			Number(this.getDeliveryTotal())
		);
		let cartTotal =
			Number(this.getCartSubTotal()) +
			Number(totalDeliveryData.deliveryTotal) +
			Number(totalDeliveryData.deliveryServiceFee) -
			(Number(this.getDiscountTotal()) + Number(this.getCoupenDiscount()));
		return cartTotal.toFixed(2);
	};
	incrementItem = (cartItem, idx) => {
		if (cartItem.quantity <= this.state.max) {
			this.setQty(
				{
					id: cartItem.id,
					quantity: cartItem.quantity + 1,
					title: cartItem.title,
					price: cartItem.price,
					discount: cartItem.discount,
					appliedOfferId: cartItem.appliedOfferId ?? null,
					isFree: cartItem.isFree ?? false,
				},
				idx
			);
		}
	};
	decreaseItem = (cartItem, idx) => {
		if (cartItem.quantity >= this.state.min) {
			this.setQty(
				{
					id: cartItem.id,
					quantity: cartItem.quantity - 1,
					discount: cartItem.discount,
					price: cartItem.price,
					title: cartItem.title,
					appliedOfferId: cartItem.appliedOfferId ?? null,
					isFree: cartItem.isFree ?? false,
				},
				idx
			);
		}
	};
	updateCartStates = () => {
		let inputForm = { ...this.state.login_details };
		if (this.state.tipPercentage != 0) {
			inputForm["tip"] = Number(
				(this.getGrandTotal() * this.state.tipPercentage) / 100
			).toFixed(2);
		}

		inputForm["taxable_amount"] = (
			Number(this.getCartTotal()) + Number(this.getExtraTotal())
		).toFixed(2);
		inputForm["total_discount"] = this.getDiscountTotal();
		inputForm["total_delivery"] = this.getDeliveryTotal();
		if (this.state.profile.tax_enabled == true) {
			inputForm["total_tax"] = Number(
				this.getTaxGroupTotal(inputForm["taxable_amount"]).amount
			).toFixed(2);
		}

		inputForm["total_amount"] = this.getPayableTotal();
		this.setState({ login_details: inputForm });
	};
	hideAddressModal = () => this.setState({ showAddressModal: false });

	hideFreeOfferItemSelectionModal = () => {
		// console.log(this.state.offers);
		// const atLeastOneOfferSelected =
		// 	this.state.offers.every((item) => !item.offerReadyToSelect) ||
		// 	this.state.offers.some(
		// 		(item) => item.offerReadyToSelect && item.offerSelected
		// 	);
		// if (atLeastOneOfferSelected || this.state.skipOfferSelection) {
		this.setState({
			showFreeOfferItemSelectionModal: false,
			selectedOffer: null,
		});
		// }
	};

	hideDelivery(name) {
		this.setState({ showHideDelivery: !this.state.showHideDelivery });
	}

	getQty = ({ id, quantity }) => {
		//console.log(id);
		//console.log(quantity);
	};
	responseFacebook(response) {
		if (this.modeOfDelevery == 0) {
			this.setDeliveryAddress();
		}
		if (typeof response.id != "undefined") {
			let lgDetails = { ...this.state.login_details };
			let fbObj = response;
			let names = (fbObj.name + " ").split(" ");
			lgDetails["name"] = fbObj.name;
			lgDetails["email"] = fbObj.email;
			lgDetails["first_name"] = names[0];
			lgDetails["last_name"] = names[1];
			lgDetails["login_medium"] = "Facebook";
			lgDetails["id"] = fbObj.id;
			lgDetails["verification"] = true;
			this.setState({ stepStage: 1 });
			this.setState({ login_details: lgDetails }, () => {
				this.toggleStep(1);

				this.validateCart();
			});
		}
	}
	is_all_fields_validated() {
		if (
			this.validator.fieldValid("first_name") == true &&
			this.validator.fieldValid("last_name") == true &&
			this.validator.fieldValid("email") == true &&
			this.validator.fieldValid("phone") == true
		) {
			return true;
		} else {
			return false;
		}
	}

	validateCart = () => {
		if (
			this.validator.fieldValid("first_name") == true &&
			this.validator.fieldValid("last_name") == true &&
			this.validator.fieldValid("email") == true &&
			this.validator.fieldValid("phone") == true
		) {
			this.setState({ cartValidate: true });
			return true;
		} else {
			this.setState({ cartValidate: false });

			this.validator.showMessages();
			return false;
		}
	};

	finishDelivery = (e) => {
		if (this.validator.allValid() == true) {
			this.processCart();
			window.location = currentURL + this.state.key_name + "/thanks";
		} else {
			this.validator.showMessages();
		}
	};

	saveCart = () => {
		let customerParams = { ...this.state.login_details };
		customerParams["order_mode"] = this.state.modeOfDelevery;
		let date_set = Moment(
			this.dataHandler.getShopingTime(this.state.key_name),
			"YYYY-MM-DD HH:mm:ss"
		);
		date_set = date_set.isSameOrAfter(Moment()) ? date_set : Moment();
		customerParams["ordered_date"] = date_set
			.utc()
			.format("YYYY-MM-DD HH:mm:ss");
		let cartObj = { ...createCartItemsOfferPairs([...this.state.cart]) };

		let inputParams = {
			restaurant_name: this.state.key_name,
			customer: JSON.stringify(customerParams),
			cart: JSON.stringify(cartObj),
		};
		this.dataHandler.setCartParams(this.state.key_name, inputParams);

		try {
			if (this.state.cartValidate == true) {
				console.log("browser", deviceDetect());

				new DataService("carts")
					.dumpCart({
						...inputParams,
						customer_device: JSON.stringify(deviceDetect()),
					})
					.then((response) => console.log("Validated Cart", response));
			}
		} catch (error) {}
		return inputParams;
	};

	processCart = () => {
		let inputForm = { ...this.state.login_details };
		if (this.state.tipPercentage != 0) {
			inputForm["tip"] = Number(
				(this.getGrandTotal() * this.state.tipPercentage) / 100
			).toFixed(2);
		}

		inputForm["taxable_amount"] = (
			Number(this.getCartTotal()) + Number(this.getExtraTotal())
		).toFixed(2);
		let totalDeliveryData = getVisibleDeliveryTotal(
			this.state.profile,
			this.state.modeOfDelevery,
			Number(this.getDeliveryTotal())
		);
		inputForm["total_discount"] = this.getDiscountTotal();
		inputForm["total_delivery"] = totalDeliveryData.deliveryTotal;
		inputForm["total_delivery_service_fee"] =
			totalDeliveryData.deliveryServiceFee;
		inputForm["total_restaurant_delivery_share"] = this.getDeliveryTotal(true);
		inputForm["total_coupens_applied"] = this.getCoupenDiscount();
		var coupensApplied = this.getCoupens(true).map((cobj) => cobj.id);
		var xtotal = 0;
		var extraCharges = this.getExtraChargeTotal(this.getCartTotal());
		if (extraCharges.length > 0) {
			inputForm["extra_payments"] = JSON.stringify(extraCharges);

			inputForm["total_extra"] = this.getExtraTotal();
			//inputForm['untaxed_total_extra']=this.getExtraTotal(false);
		}
		inputForm["applied_coupens"] = JSON.stringify(coupensApplied);
		if (this.state.profile.tax_enabled == true) {
			inputForm["total_tax"] = Number(
				this.getTaxGroupTotal(inputForm["taxable_amount"]).amount
			).toFixed(2);
		}

		inputForm["total_amount"] = this.getPayableTotal();
		this.setState({ login_details: inputForm }, () => {
			this.saveCart();
		});
	};
	addCart = () => {
		let inputParams = this.processCart();

		new DataService("carts")
			.createCart(inputParams)
			.then((response) => {
				if (response.data["status"] === "Sucess") {
					this.emptyCart();
					// history.push(this.state.key_name+'/thanks');
					window.location = currentURL + this.state.key_name + "/thanks";
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));
	};

	skipStep = () => {
		if (this.modeOfDelevery == 0) {
			this.setDeliveryAddress();
		}
		this.setState({ stepStage: 1 }, () => {
			this.toggleStep(1);
		});
	};

	emptyCart = () => {
		this.dataHandler.setCartDisbaled(this.state.key_name);

		this.setState({
			cart: [],
			steps: [true, false, false],
			stepStage: 0,
			steps: [true, false, false],
			login_details: { verification: false },
			delevery_details: {},
			deleveryForm: {},
		});
		this.setState({ login_details: {} });
	};
	setDeliveryAddress = () => {
		let inputParms = { ...this.state.login_details };
		let addressObj = this.dataHandler.getAddress(this.state.key_name);
		inputParms["address"] = addressObj.address;
		inputParms["lat"] = addressObj.lat;
		inputParms["lan"] = addressObj.lan;
		inputParms["address_components"] = addressObj.address_components;

		this.setState({ login_details: inputParms });
	};
	setCustomaize = (cartItem) => {
		let inputCart = [...this.state.cart];

		cartItem.quantity = 1;
		let total = 0;
		if (cartItem.upCellItems) {
			total = cartItem.upCellItems.reduce(function (previous, current) {
				return previous + +current.item_price;
			}, 0);
		}
		let answerTotal = 0;
		if (cartItem.customAnswers) {
			answerTotal = cartItem.customAnswers.reduce(function (
				previous,
				current
			) {
				return previous + +current.item_price;
			},
			0);
			total = total + answerTotal + Number(cartItem.price);
		}
		cartItem.price = Number(total).toFixed(2);
		inputCart.push(cartItem);
		// this.setState({ cart: inputCart });
		// this.dataHandler.setCart(this.state.key_name, inputCart);
		this.updateOffersList({ cart: inputCart });
		this.setState({
			showMenuProfile: false,
		});
	};

	addCartItem = (cartItem) => {
		let inputCart = [...this.state.cart];
		let modifiedOffers;
		inputCart.push(cartItem);
		// this.setState({ cart: inputCart }, () => {
		// 	this.dataHandler.setCart(this.state.key_name, inputCart);
		// });

		if (cartItem.appliedOfferId) {
			modifiedOffers = this.selectOfferItem(cartItem.appliedOfferId);
		}
		this.updateOffersList({ cart: inputCart, offers: modifiedOffers ?? null });
	};

	responseGoogle(response) {
		this.setDeliveryAddress();
		if (typeof response.profileObj != "undefined") {
			this.setState({ stepStage: 1 });

			let lgDetails = { ...this.state.login_details };
			let googleObj = response.profileObj;
			lgDetails["name"] = googleObj.name;
			lgDetails["email"] = googleObj.email;
			lgDetails["login_medium"] = "Google";
			lgDetails["id"] = googleObj.googleId;
			lgDetails["first_name"] = googleObj.givenName;
			lgDetails["last_name"] = googleObj.familyName;
			lgDetails["verification"] = true;
			this.setState({ stepStage: 1 });
			this.setState({ login_details: lgDetails }, () => {
				this.toggleStep(1);
				this.validateCart();
			});
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	reloadFoodData = (id) => {
		let inputParams = { name: this.state.key_name, id: id };
		this.dataHandler.getFoodProfile(inputParams).then((response) => {
			let foodObj = response.data.data;
			console.log("FoodData", foodObj);
			this.setState({ showMenuProfile: true, itemSelected: foodObj });
		});
	};
	onClickUpCellItem = (foodObj) => {
		this.reloadFoodData(foodObj.id);
	};
	timeout(delay) {
		return new Promise((res) => setTimeout(res, delay));
	}
	getMonerisPage = () => {
		let inputParams = {};

		inputParams["name"] = this.props.key_name;
		inputParams["cust_id"] = this.state.cust_id;
		inputParams["total"] = Number(this.getPayableTotal()).toFixed(2);
		this.dataHandler.getMonerisTicket(inputParams).then((response) => {
			if (response.data.status == "sucess") {
				let currentData = response.data.data;
				var ticket = currentData.ticket;
				let inputForm = { ...this.state.login_details };
				inputForm["moneris_ticket"] = ticket;
				this.setState({ login_details: inputForm }, () => {
					this.processCart();
					this.toggleStep(2, () => {
						var encodedTicket = Buffer.from(ticket).toString("base64");
						window.location =
							currentURL +
							this.props.key_name +
							"/MonerisPayPage" +
							"/" +
							encodedTicket;
					});
				});
			} else {
				alert("Moneris gateway is not responding");
			}
		});
	};
	dismissOfferNotifications = (ruleToDismiss) => {
		let offerRulesCopy = [...this.state.offerRules];
		offerRulesCopy.forEach((rule) => {
			if (rule.showNotification && ruleToDismiss.id === rule.id) {
				rule.notification_trigger = false;
				rule.showNotification = false;
			}
		});
		this.setState({ offerRules: offerRulesCopy });
		this.dataHandler.setOfferRules(this.state.key_name, offerRulesCopy);
	};
	renderTooltip = (props) => (
		<Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
			{this.getExtraChargeTotal(this.getCartTotal()).length > 0 && (
				<div>
					<div className="d-flex justify-content-between mb-2">
						<strong>
							<u>Service Charges</u>
						</strong>
					</div>
					{this.getExtraChargeTotal(this.getCartTotal())
						.filter((extra) => extra.taxable)
						.map((extra, index) => (
							<div
								key={index}
								className="d-flex justify-content-between seven-color mb-1"
							>
								<span>{extra.name}</span>
								<span>${Number(extra.valueAmount).toFixed(2)}</span>
							</div>
						))}
				</div>
			)}

			{getVisibleDeliveryTotal(
				this.state.profile,
				this.state.modeOfDelevery,
				Number(this.getDeliveryTotal())
			).deliveryServiceFee > 0 && (
				<div
					key={"index"}
					className="d-flex justify-content-between seven-color mb-1"
				>
					<span>{"Service Fee"}</span>
					<span>
						$
						{
							getVisibleDeliveryTotal(
								this.state.profile,
								this.state.modeOfDelevery,
								Number(this.getDeliveryTotal())
							).deliveryServiceFee
						}
					</span>
				</div>
			)}
			{this.state.profile.tax_enabled == true &&
				this.getTaxGroupTotal(
					Number(this.getCartTotal()) + this.getExtraTotal()
				).amount > 0 && (
					<div>
						<div className="d-flex justify-content-between mb-2">
							<strong>
								<u>Taxes</u>
							</strong>
						</div>
						<div className="d-flex justify-content-between seven-color mb-1">
							<span>
								{
									this.getTaxGroupTotal(
										Number(this.getCartTotal()) +
											Number(this.getExtraTotal())
									).name
								}
							</span>
							<span>
								${" "}
								{Number(
									this.getTaxGroupTotal(
										Number(this.getCartTotal()) +
											Number(this.getExtraTotal())
									).amount
								).toFixed(2)}
							</span>
						</div>
					</div>
				)}
			{this.getExtraChargeTotal(this.getCartTotal()).filter(
				(item) => item.taxable == false
			).length > 0 && (
				<div>
					<div className="d-flex justify-content-between mb-2">
						<strong>
							<u>Additional Charges</u>
						</strong>
					</div>
					{this.getExtraChargeTotal(this.getCartTotal()).map(
						(extra) =>
							extra.taxable == false && (
								<div className="d-flex justify-content-between seven-color mb-1">
									<span>{extra.name}</span>
									<span>
										$ {Number(extra.valueAmount).toFixed(2)}
									</span>
								</div>
							)
					)}
				</div>
			)}
		</Tooltip>
	);
	render() {
		let offerRules = [];
		if (this.state.offerRules?.length > 0) {
			offerRules = this.state.offerRules.filter(
				(rule) => rule.showNotification && rule.message
			);
		}
		document.title = this.state.profile.restaurant_name + "-" + "Checkout";
		if (this.state.cart && this.state.cart.length == 0) {
			return <Redirect to={"/" + this.state.key_name} />;
		}

		return (
			<section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
				<AddAddressModal
					show={this.state.showAddressModal}
					onHide={this.hideAddressModal}
				/>
				{this.state.selectedOffer && (
					<FreeOfferItemsSelectionModal
						cart={this.state.cart}
						selectedOffer={this.state.selectedOffer}
						show={Boolean(
							this.state.showFreeOfferItemSelectionModal &&
								this.state.selectedOffer
						)}
						onHide={this.hideFreeOfferItemSelectionModal}
						decreaseItem={this.decreaseItem}
						incrementItem={this.incrementItem}
						addCartItem={this.addCartItem}
						skipOfferSelection={this.state.skipOfferSelection}
						onSkipOfferSelection={() => {
							this.hideFreeOfferItemSelectionModal();
							this.setState({ skipOfferSelection: true });
						}}
						profile={this.state.profile}
					/>
				)}

				<Container>
					<Row>
						<Col md={5} className="mb-4">
							<div className="offer-dedicated-body-left p-3 bg-light shadow-sm rounded">
								<div className="d-flex mb-4 align-items-center">
									<Image
										fluid
										className="mr-3 rounded-circle"
										alt=""
										// src="/img/2.jpg"
										src={
											window.image_path +
											this.state.profile.logo
										}
										style={{ width: "60px", height: "60px" }}
									/>
									<div className="d-flex flex-column">
										<h6 className="mb-1 font-weight-bold">
											{this.state.profile.restaurant_name}
										</h6>
										<p className="mb-0 text-muted">
											<Icofont icon="location-pin" />{" "}
											{this.state.profile.address}
										</p>
									</div>
								</div>
								{/* <div className="bg-white rounded shadow-sm mb-2"></div> */}
								<div className="mb-3">
									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text className="bg-transparent border-0">
												<Icofont icon="comment" />
											</InputGroup.Text>
										</InputGroup.Prepend>
										<Form.Control
											as="textarea"
											name="suggestions"
											onChange={this.loginFormHandler}
											placeholder="Any suggestions? We will pass it on..."
											aria-label="With textarea"
											// className="border-radius-10"
											style={{ borderRadius: 10 }}
										/>
									</InputGroup>
								</div>
								<h5
									className="mb-2 font-weight-bold"
									style={{ textAlign: "center" }}
								>
									{orderString[this.state.modeOfDelevery]}
								</h5>
								<p
									className="mb-3 text-muted"
									style={{ textAlign: "center" }}
								>
									{this.state.cart.length} Items
								</p>
								<div className="mb-3 p-3 bg-white shadow-sm rounded">
									{this.state.cart
										.map((item, index) => ({
											...item,
											originalIndex: index,
										}))
										.sort((a, b) =>
											a.isFree === b.isFree
												? 0
												: a.isFree
												? 1
												: -1
										)
										.map((itemObj) => (
											<CheckoutItem
												item={itemObj}
												title={itemObj.title}
												price={itemObj.price}
												discount={itemObj.discount}
												priceUnit="$"
												id={itemObj.id}
												quantity={itemObj.quantity}
												show={true}
												minValue={0}
												maxValue={50}
												cartIndex={itemObj.originalIndex}
												decreaseItem={this.decreaseItem}
												incrementItem={this.incrementItem}
												getValue={this.setQty}
											/>
										))}
									{/* {this.state.cart
										.filter((item) => item.isFree)
										.map((itemObj, idx) => (
											<CheckoutItem
												item={itemObj}
												title={itemObj.title}
												price={itemObj.price}
												discount={itemObj.discount}
												priceUnit="$"
												id={itemObj.id}
												quantity={itemObj.quantity}
												show={true}
												minValue={0}
												maxValue={50}
												cartIndex={idx}
												decreaseItem={this.decreaseItem}
												incrementItem={this.incrementItem}
												getValue={this.setQty}
											/>
										))} */}
								</div>
								{offerRules?.length > 0 && (
									<div
										style={{
											position: "fixed",
											bottom: "7%",
											left: "50%",
											transform: "translateX(-50%)",
											width: "90%",
											zIndex: 1000,
										}}
									>
										{offerRules.map((rule) => (
											<Alert
												key={rule.id}
												variant="secondary"
												className="d-md-none"
												style={{
													background: "yellowGreen",
													color: "#000000",
													padding: "10px 15px",
													borderRadius: "8px",
													boxShadow:
														"0 2px 10px rgba(0, 0, 0, 0.3)",
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent:
															"space-between",
														width: "100%",
													}}
												>
													<div>
														<div
															style={{
																display: "flex",
																alignItems:
																	"center",
															}}
														>
															<span
																role="img"
																aria-label=""
																style={{
																	marginRight:
																		"8px",
																}}
															>
																🌟
															</span>
															<span>
																{rule.message}
															</span>
														</div>
													</div>
													<span
														className="icofont-close"
														style={{
															cursor: "pointer",
															marginLeft: "15px",
														}}
														onClick={() =>
															this.dismissOfferNotifications(
																rule
															)
														}
													></span>
												</div>
											</Alert>
										))}
									</div>
								)}
								{offerRules?.length > 0 && (
									<div
										style={{
											position: "fixed",
											bottom: "5%",
											left: "50%",
											transform: "translateX(-50%)",
											width: "30%",
											zIndex: 1000,
										}}
									>
										{offerRules.map((rule) => (
											<Alert
												key={rule.id}
												variant="secondary"
												className="d-none d-md-block"
												style={{
													background: "yellowGreen",
													color: "#000000",
													padding: "10px 15px",
													borderRadius: "8px",
													boxShadow:
														"0 2px 10px rgba(0, 0, 0, 0.3)",
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent:
															"space-between",
														width: "100%",
													}}
												>
													<div>
														<div
															style={{
																display: "flex",
																alignItems:
																	"center",
															}}
														>
															<span
																role="img"
																aria-label=""
																style={{
																	marginRight:
																		"8px",
																}}
															>
																🌟
															</span>
															<span>
																{rule.message}
															</span>
														</div>
													</div>
													<span
														className="icofont-close"
														style={{
															cursor: "pointer",
															marginLeft: "15px",
														}}
														onClick={() =>
															this.dismissOfferNotifications(
																rule
															)
														}
													></span>
												</div>
											</Alert>
										))}
									</div>
								)}

								{
									//delivery enabling charge verification

									this.state.modeOfDelevery == 0 &&
										this.getCartSubTotal() <
											Number(
												this.state.profile
													.delevery_enabling_charge
											) && (
											<span
												className="error_delivery"
												style={{
													height: "auto",
													borderRadius: 15,
													marginTop: 10,
												}}
											>
												{/* <span className="imgspan"><Image
                  fluid
                  className="floatleft"
                  src="/img/motorbike.png"
                /></span> */}
												The minimum order value required for
												delivery is $
												{
													this.state.profile
														.delevery_enabling_charge
												}
												. Add more to checkout
											</span>
										)
								}
								{this.state.modeOfDelevery == 0 &&
									this.state.profile.enable_delevery_free ==
										true &&
									this.getDeliveryFree() > 0 && (
										<span
											className="backgrd_unreached"
											style={{
												height: "auto",
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											{/* <span className="imgspan"><Image
                  fluid
                  className="float-left"
                  src="/img/motorbike.png"
                  /></span> */}
											<span>
												You are ${this.getDeliveryFree()}{" "}
												away from free delivery.
											</span>
										</span>
									)}

								{this.state.modeOfDelevery == 0 &&
									this.state.profile.enable_delevery_free ==
										true &&
									this.getCartSubTotal() > 0 &&
									this.getDeliveryFree() == 0 && (
										<span
											className=" backgrd"
											style={{
												height: "auto",
												backgroundColor: "#9bb341",
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											{/* <span className="imgspan"><Image
                  fluid
                  className="float-left"
                  src="/img/motorbike.png"
                  /></span> */}
											<span>
												Awesome ! The delivery is free.
											</span>
										</span>
									)}

								{this.state.modeOfDelevery == 1 &&
									this.state.profile.enable_takeout_discount ==
										true &&
									this.getTakeoutDiscount() > 0 && (
										<span
											className="backgrd_unreached"
											style={{
												height: "auto",
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											{/* <span className="imgspan"><Image
                  fluid
                  className="float-left"
                  src="/img/hot-sale.png"
                  /></span> */}
											<span>
												You are ${this.getTakeoutDiscount()}{" "}
												away from getting{" "}
												{Number(
													this.state.profile
														.take_out_discount
												).toFixed(0)}
												% discount.
											</span>
										</span>
									)}

								{this.state.modeOfDelevery == 1 &&
									this.state.profile.enable_takeout_discount ==
										true &&
									this.getTakeoutDiscount() == 0 &&
									this.getCartSubTotal() > 0 && (
										<span
											className=" backgrd"
											style={{
												height: "auto",
												backgroundColor: "#9bb341",
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											{/* <span className="imgspan"><Image
                  fluid
                  className="float-left"
                  src="/img/hot-sale.png"
                  /></span> */}
											<span>
												Awesome! You have{" "}
												{Number(
													this.state.profile
														.take_out_discount
												).toFixed(0)}
												% discount in this order.
											</span>
										</span>
									)}
								<div className="pt-2"></div>
								{this.state.offers
									.filter((item) => !item.offerReadyToSelect)
									.map((item) => (
										<div
											className=" backgrd_unreached"
											style={{
												height: "auto",
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											<span>
												{item.offerRelatedCustomerMessage}
											</span>
										</div>
									))}
								{this.state.offers
									.filter((item) => item.offerReadyToSelect)
									.map((item) => (
										<div
											className=" backgrd"
											style={{
												height: "auto",
												backgroundColor: "#9bb341",
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											<span>
												{item.offerRelatedCustomerMessage}
											</span>
										</div>
									))}
								<div className="pt-2"></div>
								{this.state.offers
									.filter((item) => item.offerReadyToSelect)
									.map((item) => (
										<div className="mb-2 bg-white rounded p-2 clearfix">
											<Button
												style={{ width: "100%" }}
												onClick={() => {
													this.setState({
														showFreeOfferItemSelectionModal: true,
														selectedOffer: item,
													});
												}}
											>
												{item.offerSelected
													? "Change Your Free Dish"
													: "Select Your Free Dish"}
											</Button>
										</div>
									))}

								<div className="mb-3 p-3 bg-white shadow-sm rounded">
									{/* <Image
										fluid
										className="float-left"
										src="/img/wallet-icon.png"
									/> */}
									<div className="d-flex justify-content-between mb-2">
										<h6 className="font-weight-bold mb-0">
											Subtotal
										</h6>
										<span>
											${" "}
											{Number(this.getCartSubTotal()).toFixed(
												2
											)}
										</span>
									</div>
									{getVisibleDeliveryTotal(
										this.state.profile,
										this.state.modeOfDelevery,
										Number(this.getDeliveryTotal())
									).deliveryTotal > 0 && (
										<div className="d-flex justify-content-between mb-2 text-muted">
											<span>Delivery Charge</span>
											<span>
												${" "}
												{
													getVisibleDeliveryTotal(
														this.state.profile,
														this.state.modeOfDelevery,
														Number(
															this.getDeliveryTotal()
														)
													).deliveryTotal
												}
											</span>
										</div>
									)}
									{this.getDiscountTotal() > 0 && (
										<div className="d-flex justify-content-between mb-2 text-muted">
											<span>Applied Discount</span>
											<span>
												${" "}
												{Number(
													this.getDiscountTotal()
												).toFixed(2)}
											</span>
										</div>
									)}
									{this.getCoupenDiscount() > 0 && (
										<div className="d-flex justify-content-between mb-2 text-muted">
											<span>Coupons Applied</span>
											<span>
												${" "}
												{Number(
													this.getCoupenDiscount()
												).toFixed(2)}
											</span>
										</div>
									)}
									{/* {this.getExtraChargeTotal(this.getCartTotal())
										.length > 0 && [
										<p className="seven-color mb-1 text-right">
											Coupons Applied:
											<span>
												${" "}
												{Number(
													this.getCoupenDiscount()
												).toFixed(2)}
											</span>
										</div>
									)} */}
									{/* {this.getExtraChargeTotal(this.getCartTotal())
										.length > 0 && [
										<p className="seven-color mb-1 text-right">
											<h6>Service Charges</h6>
										</p>,

										this.getExtraChargeTotal(
											this.getCartTotal()
										).map(
											(extra) =>
												extra.taxable == true && (
													<p className="seven-color mb-1 text-right">
														{extra.name}:{" "}
														<span>
															$
															{Number(
																extra.valueAmount
															).toFixed(2)}
														</span>
													</p>
												)
										),
									]} */}
									{this.state.profile.tax_enabled == true &&
										this.getTaxGroupTotal(
											Number(this.getCartTotal()) +
												this.getExtraTotal()
										).amount > 0 && [
											<div className="d-flex justify-content-between mb-2 text-muted">
												<span>Taxable amount</span>
												<span>
													${" "}
													{Number(
														Number(
															this.getCartTotal()
														) +
															Number(
																this.getExtraTotal()
															)
													).toFixed(2)}
												</span>
											</div>,

											// <div className="d-flex justify-content-between mb-2">
											// 	<h6 className="font-weight-bold text-right mb-2">
											// 		Applied Tax
											// 	</h6>
											// </div>,

											// <div className="d-flex justify-content-between mb-2 text-muted">
											// 	<span>
											// 		{
											// 			this.getTaxGroupTotal(
											// 				Number(
											// 					this.getCartTotal()
											// 				) +
											// 					Number(
											// 						this.getExtraTotal()
											// 					)
											// 			).name
											// 		}
											// 	</span>
											// 	<span>
											// 		${" "}
											// 		{Number(
											// 			this.getTaxGroupTotal(
											// 				Number(
											// 					this.getCartTotal()
											// 				) +
											// 					Number(
											// 						this.getExtraTotal()
											// 					)
											// 			).amount
											// 		).toFixed(2)}
											// 	</span>
											// </div>,
										]}

									{/* {this.getExtraChargeTotal(
										this.getCartTotal()
									).filter((item) => item.taxable == false)
										.length > 0 && [
										<div className="d-flex justify-content-between mb-2">
											<h6 className="font-weight-bold mb-0">
												Additional Charges
											</h6>
										</div>,

										this.getExtraChargeTotal(
											this.getCartTotal()
										).map(
											(extra) =>
												extra.taxable == false && (
													<div className="d-flex justify-content-between mb-2 text-muted">
														<span>{extra.name}</span>
														<span>
															${" "}
															{Number(
																extra.valueAmount
															).toFixed(2)}
														</span>
													</div>
												)
										),
									]} */}
									<div className="d-flex justify-content-between mb-2 text-muted">
										<span>
											Taxes and Other Fees
											<OverlayTrigger
												placement="top"
												trigger="click"
												overlay={this.renderTooltip}
												rootClose
											>
												<span
													style={{
														cursor: "pointer",
														marginLeft: "15px",
														// fontSize: "1rem", // Adjust the size of the icon
														color: "black",
													}}
												>
													<i className="icofont-info-circle" />
												</span>
											</OverlayTrigger>
										</span>
										<span>
											${" "}
											{(
												Number(this.getExtraTotal()) +
												Number(this.getExtraTotal(false)) +
												Number(
													this.getTaxGroupTotal(
														Number(
															this.getCartTotal()
														) +
															Number(
																this.getExtraTotal()
															)
													).amount
												) +
												Number(
													getVisibleDeliveryTotal(
														this.state.profile,
														this.state.modeOfDelevery,
														Number(
															this.getDeliveryTotal()
														)
													).deliveryServiceFee
												)
											).toFixed(2)}
										</span>
									</div>
									<div className="d-flex justify-content-between">
										<h6 className="font-weight-bold mb-0">
											Total
										</h6>
										<span>
											${" "}
											{Number(this.getGrandTotal()).toFixed(
												2
											)}
										</span>
									</div>

									{/* <p className="text-black mb-0 text-right"></p> */}
								</div>

								<div className="mb-3 p-3 bg-white shadow-sm rounded">
									<Form.Group className="mb-2">
										{/* <Form.Label>
											<h6>Apply Coupon</h6>
										</Form.Label> */}
										<InputGroup>
											<Form.Control
												// className="coupnText"
												value={this.state.coupenCode}
												onChange={(e) => {
													this.setState({
														coupenCode: e.target.value,
													});
												}}
												type="text"
												name="coupen"
												min="0"
												placeholder="Enter coupon code"
											/>
											<InputGroup.Append>
												<Button
													variant="primary"
													onClick={(e) => {
														var coupenCode =
															e.target.value;
														this.matchCoupenCode();
														//this.getTipInPercentage(5);
													}}
												>
													Apply Coupon
												</Button>
											</InputGroup.Append>
										</InputGroup>
									</Form.Group>
									{this.state.appliedCoupens.length > 0 &&
										this.getCoupens(true).map((coupen) => (
											<p className="mb-3 text-success font-weight-bold">
												Applied coupon {coupen.title} worth{" "}
												<span className="text-danger ">
													{" "}
													${this.valueCoupen(coupen)}
												</span>
											</p>
										))}

									<Alert
										variant={this.state.alertColor}
										show={this.state.Alertvisible}
										onClose={() =>
											this.setState({
												Alertvisible: false,
											})
										}
										dismissible
									>
										{" "}
										{this.state.alertMessage}{" "}
									</Alert>
								</div>
								<div
									className="d-flex align-items-center mb-3 p-3 bg-white shadow-sm rounded"
									style={{ justifyContent: "space-between" }}
								>
									{/* <span className="float-right ml-2"> */}
									<Form.Label className="font-weight-bold m-0">
										Tip
									</Form.Label>
									<div className="d-flex align-items-center">
										{this.state.modeOfDelevery == 1 && (
											<button
												className={`btn btn-sm btn-outline-${this.findTipPercentage(
													0
												)}`}
												onClick={() => {
													this.loginFormHandler({
														target: {
															type: "text",
															name: "tip",
															value: 0,
														},
													});

													//this.getTipInPercentage(5);
												}}
											>
												0%
											</button>
										)}

										<button
											className={`btn btn-sm btn-${this.findTipPercentage(
												5
											)}`}
											onClick={() => {
												this.setState(
													{ tipPercentage: 5 },
													() => this.processCart()
												);
												//this.getTipInPercentage(5);
											}}
										>
											5%
										</button>

										<button
											className={`btn btn-sm btn-${this.findTipPercentage(
												10
											)}`}
											onClick={() => {
												this.setState(
													{ tipPercentage: 10 },
													() => this.processCart()
												);

												// this.getTipInPercentage(10);
											}}
										>
											10%
										</button>
										<button
											className={`btn btn-sm btn-${this.findTipPercentage(
												15
											)}`}
											onClick={() => {
												this.setState(
													{ tipPercentage: 15 },
													() => this.processCart()
												);

												// this.getTipInPercentage(15);
											}}
										>
											15%
										</button>

										<input
											style={{ width: 80 }}
											className="form-control ml-3 "
											onChange={this.loginFormHandler}
											type="number"
											name="tip"
											min="0"
											value={
												this.state.tipPercentage != 0
													? Number(
															(this.getGrandTotal() *
																this.state
																	.tipPercentage) /
																100
													  ).toFixed(2)
													: this.state.login_details[
															"tip"
													  ]
											}
											placeholder="Custom Tip"
										/>
									</div>
									{/* </span> */}
								</div>
								{this.state.tipPercentage == 0 &&
									this.state.modeOfDelevery == 0 && (
										<div className="mb-2 rounded p-2 clearfix tipcolour">
											<span className="d-flex justify-content-center">
												100% of the Tip goes to the driver
											</span>
										</div>
									)}
								<h4 className="mb-0  text-center font-weight-bold">
									Total : ${" "}
									{Number(this.getPayableTotal()).toFixed(2)}
								</h4>
							</div>
							{/* <div className="pt-2"></div> */}
						</Col>
						{!this.state.showStripeForm ? (
							<Col md={7}>
								<div className="offer-dedicated-body-left">
									{this.state.itemSelected && (
										<ViewMenuDiscriptionModel
											show={this.state.showMenuProfile}
											onHide={this.hideMenuProfile}
											itemSelected={this.state.itemSelected}
											setCustomaize={this.setCustomaize}
										/>
									)}
									{this.state.steps[0] === true &&
										this.getUpCellItems().length > 0 && (
											<div className="bg-white rounded shadow-sm p-4 mb-4">
												<h6 className="mb-3">
													You may also like
												</h6>
												<ItemsCarousel
													recomandedItems={this.getUpCellItems()}
													decreaseItem={this.decreaseItem}
													incrementItem={
														this.incrementItem
													}
													getQty={this.getQty}
													show={
														this.state.showMenuProfile
													}
													onHide={this.hideMenuProfile}
													itemSelected={
														this.state.itemSelected
													}
													setCustomaize={
														this.setCustomaize
													}
													onClickUpCellItem={
														this.onClickUpCellItem
													}
												/>
											</div>
										)}
									{/* <div className="pt-2"></div> */}
									{/* <div></div> */}

									{this.state.steps[1] === true && (
										<DeleveryForm
											setShowStripeForm={() =>
												this.setStripeFormShow(true)
											}
											validator={this.validator}
											toggleStep={this.toggleStep}
											addCart={this.addCart}
											shopTime={Moment(
												this.dataHandler.getShopingTime(
													this.props.key_name
												)
											).format(
												"dddd, MMMM Do YYYY, h:mm:ss a"
											)}
											shopHours={moment
												.duration(
													Moment(
														this.dataHandler.getShopingTime(
															this.props.key_name
														)
													).diff(Moment())
												)
												.hours()}
											key_name={this.props.key_name}
											setStripePayment={this.setStripePayment}
											setPayment={this.setPayment}
											modeOfDelevery={
												this.state.modeOfDelevery
											}
											setDeliveryAddress={
												this.setDeliveryAddress
											}
											getMonerisPage={this.getMonerisPage}
											loginFormHandler={this.loginFormHandler}
											login_details={this.state.login_details}
											cartValidate={this.state.cartValidate}
											cust_id={this.state.cust_id}
											profile={this.state.profile}
											getCartSubTotal={this.getCartSubTotal}
											payableTotal={Number(
												this.getPayableTotal()
											).toFixed(2)}
										></DeleveryForm>
									)}
									{this.state.steps[0] === true &&
									(this.state.offers.every(
										(item) => !item.offerReadyToSelect
									) ||
										this.state.offers.some(
											(item) =>
												item.offerReadyToSelect &&
												item.offerSelected
										) ||
										this.state.skipOfferSelection) ? (
										<LoginForm
											responseGoogle={this.responseGoogle}
											responseFacebook={this.responseFacebook}
											skipStep={this.skipStep}
										></LoginForm>
									) : (
										<Row>
											{this.state.steps[0] === true && (
												<Col
													md={9}
													lg={8}
													className="mx-auto  mt-4"
													style={{ alignItems: "center" }}
												>
													<h5
														className="login-heading mb-4"
														style={{
															textAlign: "center",
														}}
													>
														You have some offers, Please
														go through
													</h5>
													{this.state.offers
														.filter(
															(item) =>
																item.offerReadyToSelect
														)
														.map((item) => (
															<div className="mb-2 bg-white rounded p-2 clearfix">
																<Button
																	style={{
																		width: "100%",
																	}}
																	onClick={() => {
																		this.setState(
																			{
																				showFreeOfferItemSelectionModal: true,
																				selectedOffer:
																					item,
																			}
																		);
																	}}
																>
																	Select Your Free
																	Dish
																</Button>
															</div>
														))}
												</Col>
											)}
										</Row>
									)}
								</div>
								{this.state.steps[2] === true && (
									<MonerisCheckoutForm></MonerisCheckoutForm>
								)}
							</Col>
						) : (
							<Col md={7}>
								<div className="offer-dedicated-body-left">
									<StripeForm
										stripePromise={this.state.stripePromise}
										price={parseInt(
											this.getPayableTotal().replace(".", ""),
											10
										)}
										rawPrice={this.getPayableTotal()}
										setStripePaymentUsingIntent={
											this.setStripePaymentUsingIntent
										}
										setStripeLoading={this.setStripeLoading}
										setStripeFormShow={this.setStripeFormShow}
										stripeLoading={this.state.stripeLoading}
										stripeError={this.state.stripeError}
										setStripeError={this.setStripeError}
										businessName={
											this.state.profile.restaurant_name
										}
										currency={
											this.state.profile.country_config
												? this.state.profile.country_config[
														"currency"
												  ]
												: "CAD"
										}
										customerDetails={this.state.login_details}
									/>
								</div>
							</Col>
						)}
					</Row>
				</Container>
			</section>
		);
	}
}

class LoginForm extends React.Component {
	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (
			<Row>
				<Col md={9} lg={8} className="mx-auto pl-5 pr-5 ">
					<h3 className="login-heading mb-4">One more step to go!</h3>
					<Form>
						<Button
							className="btn btn-lg  btn-block btn-logiCn  text-white font-weight-bold mb-2"
							onClick={() => {
								this.props.skipStep();
							}}
						>
							Checkout as Guest
						</Button>

						<p className="text-center my-4">Or</p>

						<p className="text-center">
							Secure Login with your social media profile to checkout
							faster and keep track of your orders
						</p>

						<div className="row">
							<div className="col pr-2">
								<FacebookLogin
									appId="439130200506455" //APP ID NOT CREATED YET
									fields="name,email,picture"
									textButton="facebook"
									callback={this.props.responseFacebook}
									render={(renderProps) => (
										<button
											onClick={renderProps.onClick}
											className="btn-facebook btn pl-1 pr-1 btn-lg  font-weight-normal text-white btn-block text-uppercase"
										>
											<FontAwesome
												icon="facebook"
												className="mr-2"
											/>{" "}
											Facebook
										</button>
									)}
									cssClass="btn-facebook btn pl-1 pr-1 btn-lg  font-weight-normal text-white btn-block text-uppercase"
									icon="facebook"
								/>
							</div>

							<div className="col pl-2">
								<GoogleLogin
									clientId="682154375902-qjsbe5lo2hvr34d9egi23ciqtus0qqtu.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
									render={(renderProps) => (
										<button
											onClick={renderProps.onClick}
											className="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase"
										>
											<FontAwesome
												icon="google"
												className="mr-2"
											/>{" "}
											Google
										</button>
									)}
									buttonText="Google"
									onSuccess={this.props.responseGoogle}
								/>
							</div>
						</div>
					</Form>
				</Col>
			</Row>
		);
	}
}

class DeleveryForm extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.login_details = { ...this.props.login_details };
		this.state = {
			createForm: {},

			center: { lat: 9.5137, lng: 76.569092 },
		};
	}

	render() {
		return (
			<div className="bg-white rounded shadow-sm p-4 mb-4 ">
				{/* <h4 className="mb-1"></h4> */}
				<h6 className="mb-3 text-black-50">Enter Your Information </h6>

				<Row>
					<Col md={12}>
						<div className="form-row">
							<Form.Group className="col-md-12">
								<Form.Label>First Name</Form.Label>
								{this.props.validator.message(
									"first_name",
									this.props.login_details["first_name"],
									"required",
									{ className: "text-danger" }
								)}
								<InputGroup>
									<Form.Control
										name="first_name"
										defaultValue={
											this.login_details["first_name"]
										}
										onChange={this.props.loginFormHandler}
										type="text"
										placeholder="First Name"
										className="addresslocat"
									/>
								</InputGroup>
								<Form.Label>Last Name</Form.Label>
								{this.props.validator.message(
									"last_name",
									this.props.login_details["last_name"],
									"required",
									{ className: "text-danger" }
								)}

								<InputGroup>
									<Form.Control
										name="last_name"
										defaultValue={
											this.login_details["last_name"]
										}
										onChange={this.props.loginFormHandler}
										type="text"
										placeholder="Last Name"
										className="addresslocat"
									/>
								</InputGroup>
							</Form.Group>
							<Form.Group className="col-md-12">
								<Form.Label>Contact Number</Form.Label>
								{this.props.validator.message(
									"phone",
									this.props.login_details["phone"],
									"required|phone",
									{ className: "text-danger" }
								)}

								<InputGroup>
									<Form.Control
										type="number"
										name="phone"
										value={this.props.login_details["phone"]}
										placeholder="Landline/Mobile Number"
										onChange={this.props.loginFormHandler}
										className="addresslocat"
										inputMode="tel"
									/>
								</InputGroup>
							</Form.Group>
							<Form.Group className="col-md-12">
								<Form.Label>Email</Form.Label>
								{this.props.validator.message(
									"email",
									this.props.login_details["email"],
									"required|email",
									{ className: "text-danger" }
								)}

								<InputGroup>
									<Form.Control
										type="email"
										value={this.props.login_details["email"]}
										name="email"
										onChange={this.props.loginFormHandler}
										placeholder="Valid Email Id"
										className="addresslocat"
									/>
								</InputGroup>
							</Form.Group>
							{this.props.modeOfDelevery == 0 && (
								<Form.Group className="col-md-12">
									<Form.Label>Address</Form.Label>

									<InputGroup>
										<Form.Control
											type="text"
											name="address"
											value={
												this.props.login_details["address"]
											}
											disabled={true}
											className="addresslocat"
											placeholder="Address"
										/>
									</InputGroup>
								</Form.Group>
							)}

							{this.props.modeOfDelevery == 0 && (
								<Form.Group className="col-md-12">
									<Form.Label>Delivery Instructions</Form.Label>
									<Form.Control
										type="text"
										onChange={this.props.loginFormHandler}
										value={
											this.props.login_details[
												"delivery_instructions"
											]
										}
										name="delivery_instructions"
										placeholder="Type any delivery Instructions"
										className="addresslocat"
									/>
								</Form.Group>
							)}
						</div>
					</Col>
				</Row>

				{this.props.shopHours >= 1 && (
					<Row>
						<Col md={12}>
							<p className="shedule-text-colour">
								You are scheduling this order for “
								{this.props.shopTime}{" "}
								{this.props.modeOfDelevery == 0
									? "Delivery"
									: "Takeout"}
								. To make change, click on{" "}
								<a href={this.props.key_name}>home</a> and then
								“Change Order Mode”
							</p>
						</Col>
					</Row>
				)}
				<Row>
					{this.props.profile.online_payment_mode ==
						ONLINE_PAYMENT_MODES["STRIPE"] && (
						<Col md={6}>
							<Form.Group className="col-md-12">
								<Form.Label> </Form.Label>
								<button
									className="btn btn-primary btn-lg btn-block"
									disabled={
										!(this.props.cartValidate &&
										(this.props.modeOfDelevery == 1 ||
											this.props.getCartSubTotal() >=
												Number(
													this.props.profile
														.delevery_enabling_charge
												))
											? true
											: false)
									}
									onClick={this.props.setShowStripeForm}
								>
									PAY ONLINE
								</button>

								{/* <StripeCheckoutButton
										button_stat={
											this.props.cartValidate &&
											(this.props.modeOfDelevery == 1 ||
												this.props.getCartSubTotal() >=
													Number(
														this.props.profile
															.delevery_enabling_charge
													))
												? true
												: false
										}
										price={this.props.payableTotal}
										setStripePayment={
											this.props.setStripePayment
										}
										cust_id={this.props.cust_id}
										login_details={this.props.login_details}
										profile={this.props.profile}
									></StripeCheckoutButton> */}
							</Form.Group>
						</Col>
					)}
					{((this.props.modeOfDelevery == 1 &&
						this.props.profile.disable_pay_at_store == false) ||
						(this.props.modeOfDelevery == 0 &&
							this.props.profile.disable_pay_on_delivery ==
								false)) && (
						<Col md={6}>
							<Form.Group className="col-md-12">
								<Form.Label> </Form.Label>
								<Button
									className="btn btn-lg  btn-block btn-login text-uppercase text-white font-weight-bold mb-2"
									disabled={
										this.props.cartValidate &&
										(this.props.modeOfDelevery == 1 ||
											this.props.getCartSubTotal() >=
												Number(
													this.props.profile
														.delevery_enabling_charge
												))
											? false
											: true
									}
									onClick={(e) => {
										e.target.setAttribute(
											"disabled",
											"disabled"
										);

										this.props.setPayment(
											paymentStrings[
												this.props.modeOfDelevery
											]
										);
									}}
								>
									{paymentStrings[this.props.modeOfDelevery]}
								</Button>
							</Form.Group>
						</Col>
					)}
					{this.props.profile.online_payment_mode ==
						ONLINE_PAYMENT_MODES["MONERIS_HOSTED_PAGE"] && (
						<Col md={6}>
							<Form.Group className="col-md-12">
								<Form.Label> </Form.Label>
								<Moneris
									storeId="ps_store_id"
									//i think need to remove disabled , no use, instaed need button stat
									disabled={
										this.props.cartValidate &&
										(this.props.modeOfDelevery == 1 ||
											this.props.getCartSubTotal() >=
												Number(
													this.props.profile
														.delevery_enabling_charge
												))
											? false
											: true
									}
									button_stat={
										this.props.cartValidate &&
										(this.props.modeOfDelevery == 1 ||
											this.props.getCartSubTotal() >=
												Number(
													this.props.profile
														.delevery_enabling_charge
												))
											? false
											: true
									}
									cust_id={this.props.cust_id}
									login_details={this.props.login_details}
									profile={this.props.profile}
									amount={this.props.payableTotal}
								></Moneris>
							</Form.Group>
						</Col>
					)}

					{this.props.profile.online_payment_mode ==
						ONLINE_PAYMENT_MODES["MONERIS_CHECKOUT_PAGE"] && (
						<Col md={6}>
							<Form.Group className="col-md-12">
								<Form.Label> </Form.Label>

								<button
									className={"btn btn-primary btn-lg btn-block"}
									disabled={
										this.props.cartValidate &&
										(this.props.modeOfDelevery == 1 ||
											this.props.getCartSubTotal() >=
												Number(
													this.props.profile
														.delevery_enabling_charge
												))
											? false
											: true
									}
									onClick={(e) => {
										this.props.getMonerisPage();

										//this.getTipInPercentage(5);
									}}
								>
									{" "}
									PAY ONLINE
								</button>
							</Form.Group>
						</Col>
					)}
				</Row>
				<TermsAndConditionsLink />
			</div>
		);
	}
}

const Moneris = (props) => {
	let rawHTML =
		'<FORM METHOD="POST" ACTION=' +
		props.profile.payment_provided_url +
		'><INPUT TYPE="HIDDEN" NAME="ps_store_id" VALUE="' +
		props.profile.payment_app_id +
		'"><INPUT TYPE="HIDDEN" NAME="hpp_key" VALUE="' +
		props.profile.hpp_key +
		'"><INPUT TYPE="HIDDEN" NAME="charge_total" VALUE="' +
		props.amount +
		'">' +
		'<button type="SUBMIT" class="btn btn-primary btn-lg btn-block"' +
		(props.button_stat && "disabled") +
		">PAY ONLINE</button>" +
		'<INPUT TYPE="HIDDEN" NAME="ship_first_name" VALUE="' +
		props.profile.title +
		'"></INPUT>' +
		'<INPUT TYPE="HIDDEN" NAME="cust_id" VALUE="' +
		props.cust_id +
		'"></INPUT>' +
		'<INPUT TYPE="HIDDEN" NAME="ship_address_one" VALUE="' +
		props.profile.address +
		'"></INPUT>' +
		'<INPUT TYPE="HIDDEN" NAME="bill_first_name" VALUE="' +
		props.login_details["first_name"] +
		'"></INPUT>' +
		'<INPUT TYPE="HIDDEN" NAME="bill_last_name" VALUE="' +
		props.login_details["last_name"] +
		'"></INPUT>' +
		'<INPUT TYPE="HIDDEN" NAME="bill_address_one" VALUE="' +
		props.login_details["address"] +
		'"></INPUT>' +
		'<INPUT TYPE="HIDDEN" NAME="delivery_only" VALUE="0"></INPUT>' +
		"</FORM>";

	return (
		<div>
			{
				<div
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(rawHTML),
					}}
				/>
			}
		</div>
	);
};

class MonerisCheckoutForm extends React.Component {
	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (
			<div className="bgwhite rounded shadowsm p4 mb4 mt3">
				{
					<div
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								'<div id="outerDiv" style="width:400px"; height"300px"><div id="monerisCheckout"></div></div>'
							),
						}}
					/>
				}
			</div>
		);
	}
}

export default Checkout;
